import Vue from 'vue'

import Vuex from 'vuex'
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
    preLoad: 2,
    // error: 'dist/error.png',
    // loading: 'dist/loading.gif',
    attempt: 2,
})
let pjson = require('../package.json');
console.log(pjson.version);

import {ApmVuePlugin} from '@elastic/apm-rum-vue'
// import 'swiper/css/swiper.css'
import config from '../public/js/config.js'

Vue.use(ApmVuePlugin, {
    router,
    config: {
        serviceName: 'teleportal-spa-' + config.VUE_APP_ENV,
        serverUrl: 'https://logging.slm.ua/remote_apm/apm',
        serviceVersion: pjson.version,
        active: true,
        instrument: false,
        transactionSampleRate: 1,
        environment: config.VUE_APP_ENV
    }
})

Vue.use(Vuex)

import App from './App.vue'

import router from './router'


// import '../src/assets/style/less/auth/front.css'

// import '../src/assets/js/components.js'

import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
    keyName: 'metaInfo',
    attribute: 'data-vue-meta',
    ssrAttribute: 'data-vue-meta-server-rendered',
    tagIDKeyName: 'vmid',
    refreshOnceOnNavigation: true
})

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import {store} from '@/store/store.js'

Vue.config.productionTip = false
// import '@/assets/js/gtm.js'
// import '@/assets/js/gtm_call.js'
// window.addEventListener('pageshow', function() {
new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')
// })

