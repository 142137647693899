<template>
    <div id="app" class="spa-content">
      <nprogress-container></nprogress-container>
      <headMain/>
      <router-view>
      </router-view>
<!--      <foot/>-->
    </div>
</template>

<script>
function gemius_pending(i) { window[i] = window[i] || function() {var x = window[i+'_pdata'] = window[i+'_pdata'] || []; x[x.length]=arguments;};};gemius_pending('gemius_hit'); gemius_pending('gemius_event'); gemius_pending('pp_gemius_hit'); gemius_pending('pp_gemius_event');(function(d,t) {try {var gt=d.createElement(t),s=d.getElementsByTagName(t)[0],l='http'+((location.protocol=='https:')?'s':''); gt.setAttribute('async','async');gt.setAttribute('defer','defer'); gt.src=l+'://gaua.hit.gemius.pl/xgemius.js'; s.parentNode.insertBefore(gt,s);} catch (e) {}})(document,'script');  // eslint-disable-line

import headMain from '@/components/header.vue'
// import foot from '@/components/footer.vue'
import NprogressContainer from 'vue-nprogress/src/NprogressContainer'
// import config from "../../../public/js/config";
export default {
  name: 'App',
  components: {
    headMain,
    NprogressContainer,
    // foot,
  },
  data() {
    return {
      // displayURL: ""
    }
  },

  watch: {
    $route: function () {
      // this.$store.dispatch('getHeaderText')
      // this.$store.dispatch('getFooterText')
      this.$store.dispatch(this.$route.name)
    },
  },

  mounted() {
    // this.$store.dispatch('getHeaderText')
    // this.$store.dispatch('getFooterText')
    // this.displayURL = config.VUE_APP_API_BASE_URL || process.env.VUE_APP_API_BASE_URL
  },
  updated() {

  },
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { content: 'width', name: 'MobileOptimized'},
      { content: 'yes', name: 'apple-mobile-web-app-capable'},
      { content: 'true', name: 'HandheldFriendly'},
      { name: 'SKYPE_TOOLBAR', content: 'SKYPE_TOOLBAR_PARSER_COMPATIBLE'},
      { name: 'format-detection', content: 'telephone=no'},
      {'http-equiv':'X-UA-Compatible', content:'IE=edge'},
      {'http-equiv':'Content-Type', content:'text/html; charset=utf-8'},

    ],

    // all titles will be injected into this template

  }
}
</script>
<style lang="less">
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
