import Vue from 'vue'

// This variable will hold the reference to
// document's click handler
let handleOutsideClick
const outside = Vue.directive('clickoutside', {
    bind(el, binding, vnode) {
            handleOutsideClick = (e) => {
                e.stopPropagation()
                const {handler, exclude} = binding.value
                let clickedOnExcludedEl = false
                exclude.forEach(refName => {
                    if (!clickedOnExcludedEl) {
                        const excludedEl = vnode.context.$refs[refName]
                        clickedOnExcludedEl = excludedEl.contains(e.target)
                    }
                })
                if (!el.contains(e.target) && !clickedOnExcludedEl && window.innerWidth < 1259) {
                    vnode.context[handler]()
                }
            }
            document.addEventListener('click', handleOutsideClick)
            document.addEventListener('touchstart', handleOutsideClick)
    },
    unbind() {
        document.removeEventListener('click', handleOutsideClick)
        document.removeEventListener('touchstart', handleOutsideClick)
    }
})

export default {
    directives: {
        outside
    }
};