const config = (() => {
    return {
        VUE_APP_ENV: window.location.hostname === 'tp-front-proxy-stage.teleportal.ua' ? 'staging' : window.location.hostname === 'teleportal.ua' ? 'production' : 'local',
        // VUE_APP_API_BASE_IMG: window.location.hostname === 'tp-front-proxy-stage.teleportal.ua' ? '' : 'https://teleportal.ua',
        VUE_APP_API_BASE_IMG: 'https://teleportal.ua',
        VUE_APP_API_AVATAR: 'https://tp-front-proxy-stage.teleportal.ua',
        // VUE_APP_API_AVATAR: 'http://tp.local',

        // back api
        VUE_APP_API_BASE_URL: window.location.hostname === 'tp-front-proxy-stage.teleportal.ua' ? 'https://tp-back-stage.starlight.digital' : 'https://tp-back.starlight.digital',
        // VUE_APP_API_BASE_URL: 'https://tp-back-stage.starlight.digital',
        // VUE_APP_API_BASE_URL: 'http://localhost:2027',
        VUE_APP_FREEZER_URL: 'https://freezer.starlight.digital/vprogress?',

        // subscribes api
        VUE_APP_API_SUBSCRIBES_URL: 'https://billing-api-stage.teleportal.ua',
        // VUE_APP_API_SUBSCRIBES_URL: 'http://localhost:2028',

        // localhost
        // VUE_APP_KEYCLOAK_URL: 'http://localhost:8080/',
        // VUE_APP_KEYCLOAK_REALM: 'tp_auth',
        // VUE_APP_KEYCLOAK_CLIENT_ID: 'vue_app',

        // stage
        VUE_APP_KEYCLOAK_URL: 'https://test-keycloak.starlight.digital/',
        VUE_APP_KEYCLOAK_REALM: 'Test-vue',
        VUE_APP_KEYCLOAK_CLIENT_ID: 'vue',
    };
})();
export default config
