<template>
  <header class="tp-header">
    <div class="tp-container d-flex align-items-center header-wrap">
      <a ref="hamburger"
         @click="navActive = !navActive"
         id="tp-mobile__menu-btn"
         class="tp-mobile__menu-btn hidden-large"
      ></a>
      <div class="row tp-header__logo-holder align-items-center">
        <div class="header-logo">
          <router-link :to="'/' + $store.state.lang" class="tp-header__logo-link">
            <img src="@/assets/images/icons/svg/teleportal_logo.svg" alt="Телепортал">
          </router-link>
        </div>
      </div>

      <div v-clickoutside="{
               exclude: ['hamburger'],
               handler: 'closeMenu'
               }" class="tp-sidebar-nav" id="tp-sidebar-nav" v-show="navActive">
        <ul id="nav" class="tp-sidebar-nav__list tp-sidebar-nav__list">
          <li v-for="item in headerText" :key="item.slug"
              class="tp-sidebar-nav__item"
              active-class="active"
          >
            <router-link :to="'/' + $store.state.lang + '/' + item.slug">{{ item.title }}</router-link>
<!--            <router-link :to="'/' + $store.state.lang + '/' + item.slug">{{ item.title }}</router-link>-->
          </li>
          <li class="lang-switcher tp-sidebar-nav__item" v-if="window.width < 1259 && $store.state.lang === 'ru'">
            <button @click="changeLang" class="sync tp-header__lang tp-header__lang-uk">
              <span>Укр</span>
            </button>
          </li>
          <li class="lang-switcher tp-sidebar-nav__item" v-if="window.width < 1259 && $store.state.lang === 'ua'">
            <button @click="changeLang" class="sync tp-header__lang tp-header__lang-ru">
              <span>Рус</span>
            </button>
          </li>
        </ul>
      </div>

      <div class="right-box">
        <div class="tp-header__search">
          <div class="tp-header__search-bl">
            <router-link :to="`/${this.$store.state.lang}/` +'search'" id="tp-header__search"></router-link>
          </div>
        </div>
        <!--        <div class="tp-header__profile align-items-center">-->
        <!--          <div class="" id="header-user-info-view">-->
        <!--            <div class="tp-header__profile-name tp-header__profile-name_unreg">-->
        <!--              <router-link to="/register" id="header-enter" href="">-->
        <!--                <span v-if="$store.state.lang !== 'ru'">Увійти</span>-->
        <!--                <span v-if="$store.state.lang !== 'ua'">Войти</span>-->
        <!--              </router-link>-->
        <!--            </div>-->
        <!--            &lt;!&ndash;                  <div class="tp-header__profile_reg" id="user-profile">&ndash;&gt;-->
        <!--            &lt;!&ndash;                    <div class="tp-header__profile-avatar">&ndash;&gt;-->
        <!--            &lt;!&ndash;                      <div class="avatar-wrap">&ndash;&gt;-->
        <!--            &lt;!&ndash;                        <img src="https://teleportal.ua/images/img/avatar.png"/>&ndash;&gt;-->
        <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
        <!--            &lt;!&ndash;                      &lt;!&ndash;                  <div class="avatar-wrap">&ndash;&gt;&ndash;&gt;-->
        <!--            &lt;!&ndash;                      &lt;!&ndash;                    <img src="/images/icons/png/icon_plus_inactive.png"/>&ndash;&gt;&ndash;&gt;-->
        <!--            &lt;!&ndash;                      &lt;!&ndash;                  </div>&ndash;&gt;&ndash;&gt;-->
        <!--            &lt;!&ndash;                      <ul class="tp-header__profile-menu">&ndash;&gt;-->
        <!--            &lt;!&ndash;                      </ul>&ndash;&gt;-->
        <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
        <!--            &lt;!&ndash;                    <div class="tp-header__profile-name tp-hidden__name">&ndash;&gt;-->
        <!--            &lt;!&ndash;                      <a href="" id="header-user-name">Юзернейм</a>&ndash;&gt;-->
        <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->

        <!--            &lt;!&ndash;                    <div class="popreg">&ndash;&gt;-->
        <!--            &lt;!&ndash;                      <a class="popreg__close"></a>&ndash;&gt;-->
        <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
        <!--            &lt;!&ndash;                  </div>&ndash;&gt;-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="tp-header__lang-bl tp-header__lang tp-header__lang_desk">
          <button @click="changeLang"
                  v-if="$store.state.lang === 'ru'"
                  class="sync tp-header__lang tp-header__lang-uk" ref="langUaDesk">
            <span>Укр</span>
          </button>
          <button @click="changeLang"
                  v-if="$store.state.lang === 'ua'"
                  class="sync tp-header__lang tp-header__lang-ru" ref="langRuDesk">
            <span>Рус</span>
          </button>
        </div>

      </div>
    </div>
    <div class="top_b_container cookie_container" v-if="!get_data()">
      <div class="cookie-pop">
        <div class="cookie-wrap collection-wrap col-md-12 ">
          <div class="cookie-text">
            <div class="cookie-text-inner" v-if="$store.state.lang !== 'ru'">Ми використовуємо cookies, щоб
              проаналізувати та покращити роботу нашого
              сайту, персоналізувати рекламу.
              Продовжуючи відвідування сайту, ви надаєте згоду на використання cookies та погоджуєтесь з умовами
              Договору користувача і
              <router-link to="/agreement" target="_blank"
                           class="cookie__link tp-video-next__title">політики конфіденційності.
              </router-link>
            </div>
            <div class="cookie-text-inner" v-if="$store.state.lang !== 'ua'">Мы используем cookies, чтобы
              проанализировать и улучшить работу нашего сайта, настроить рекламу.
              Продолжая посещение, вы даете согласие на использование cookies и согласны с условиями Соглашения
              пользователя и
              <router-link to="/agreement" target="_blank"
                           class="cookie__link tp-video-next__title">политикой конфиденциальности.
              </router-link>
            </div>
            <div class="cookie-text-inner cookie-text-inner_confid">
              <div class="cookie-text-inner_wrap">

              </div>
            </div>
          </div>
          <button id="cookie_agree" class="cookie-agree">
            <span v-if="$store.state.lang !== 'ru'" class="cookie-agree__text" @click="addAgreeCookie">ПОГОДЖУЮСЬ</span>
            <span v-if="$store.state.lang !== 'ua'" class="cookie-agree__text" @click="addAgreeCookie">СОГЛАШАЮСЬ</span>
          </button>
        </div>
      </div>
    </div>
  </header>

</template>

<script>
import outside from '../../src/assets/js/directives.js'

export default {
  name: 'headMain',
  data() {
    return {
      navActive: false,
      window: {
        width: 0
      },
    }
  },
  directives: {
    outside
  },
  state: {
    headerText: []
  },
  beforeCreate() {
    // this.$store.dispatch('getHeaderText')
  },
  watch: {
    $route: function () {
      if (this.window.width < 1259) {
        this.navActive = false;
      }
      // this.$store.dispatch('getHeaderText')
    },
  },
  created() {
    window.addEventListener('resize', this.getWinSize);
    window.addEventListener('scroll', this.handleScroll);
    this.getWinSize();
    // console.log(this.language)
  },
  mounted() {
    // this.deleteEvent();
    // if(this.language === 'ua') {
    //   this.$refs.langRuDesk.style.cssText = 'display: none'
    // }
    // if(this.language === 'ru') {
    //   this.$refs.langUaDesk.style.cssText = 'display: none'
    // }
    if (localStorage.cookieAgree) {
      this.cookieAccept = true
    }
    console.log(this.cookieAccept)
  },
  updated() {
    // console.log(this.$store.state.language)
  },

  destroyed() {
    window.removeEventListener('resize', this.getWinSize);
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    // deleteEvent: function() {
    //   this.headerText.splice(2, 1);
    // },
    closeMenu() {
      this.navActive = false
      console.log(this.navActive)
    },

    changeLang() {
      this.$store.dispatch('changeLang')

      this.$router.push({
        name: this.$router.currentRoute.name,
        params: {lang: this.$store.state.lang}
      })
    },

    getWinSize() {
      this.window.width = window.innerWidth;
      if (this.window.width < 1259) {
        this.navActive = false
      } else if (this.window.width > 1259) {
        this.navActive = true
      }
    },

    handleScroll() {
      let headerMenu = document.getElementById('tp-sidebar-nav');
      let vm = this;
      if (headerMenu.offsetWidth > 0 && this.window.width < 1259) {
        headerMenu.classList.remove("isActive")
        if (!headerMenu.classList.contains('isActive')) {
          vm.navActive = false
        }
        console.log('test')
      }
    },
    addAgreeCookie() {
      if (!localStorage.cookieAgree) {
        localStorage.setItem('cookieAgree', 1)
        this.$forceUpdate()
      }
    },
    get_data: function () {
      return localStorage.getItem('cookieAgree');
    }

    // решить, надо ли этот функционал (прячет мобменю при переходе на страницу)
    // hideMobmenuOnClick() {
    //   if(this.window.width < 1259) {
    //     this.navActive = false
    //   }
    // }
  },
  computed: {
    headerText() {
      return [...this.$store.state.headerText].slice(0, 3).concat([...this.$store.state.headerText].slice(2 + 1));
    },
    // listenToLanguage() {
    // return this.$store.state.language
    // Or return basket.getters.fruitsCount
    // (depends on your design decisions).
    // }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.tp-header {
  width: 100%;
  background:  linear-gradient(to top, transparent, #37393c);
  background-size: contain;
  height: 50px;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;

  &-logo {
    margin-left: 15px;
  }

  &__logo-holder {
    //height: 45px;
  }

  &__logo-link {
    display: table-cell;
    vertical-align: middle;
    position: relative;

    //&:after {
    //  content: "beta";
    //  position: absolute;
    //  right: -28px;
    //  top: 0;
    //  color: #ed5024;
    //  font-family: Arial, Helvetica, sans-serif;
    //  font-size: 12px;
    //  font-weight: 200;
    //}

    & img {
      width: 147px;
      height: 32px;
    }
  }
}
.header-wrap {
  margin: 0 65px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
  height: 45px;
}

.tp-header {
  display: flex;
  align-items: center;
  height: 65px;
  background:#000;
  z-index: 999;

  &__logo-holder{
    max-width: 147px;
  }

  &__lang {
    background: none;
    outline: none;
    border: none;
    text-transform: uppercase;
    color: @white;


    & button {
      margin-left: 45px;
      color: @white;
      font-size: 16px;
      font-family: Arial, Helvetica, sans-serif;
      text-decoration: none;
      background: none;
      outline: none;
      border: none;
      text-transform: uppercase;
      cursor: inherit;
      &:hover {
        color: @white;
      }
    }
    &-bl {
      text-transform: uppercase;

      button .is-active {
        height: 24px;
        width: 24px;
        font-weight: 400;
        font-size: 14px;
        position: absolute;
        text-transform: uppercase;
        -moz-border-radius: 50%;
        border-radius: 50%;
        color: #fff;
        display: none;
      }

      a:hover {
        color: #f04e23;
        text-decoration: none;
      }
    }

    & span {
      cursor: pointer;
    }
  }

  &__right {
    margin-left: 45px;
  }

  &__profile-avatar {
    float: left;
    margin-right: 10px;
    position: relative;
    margin-top: 5px;

    &-wrap {
      border-radius: 50%;
      overflow: hidden;
    }

    & img {
      width: 29px;
      height: 30px;
      display: block;
      border-radius: 50%;
    }
  }

  &__profile-name  {
    line-height: 45px;
    color: @white !important;
    text-transform: uppercase;
    float: left;

    &:hover, &:focus, &:active {
      color: @white;
      text-decoration: none;
    }

    & a  {
      color: @white;
      text-decoration: none;

      &:hover, &:focus, &:active {
        color: @white;
        text-decoration: none;
      }
    }
  }

  &__profile_reg {
    cursor: pointer;
    overflow: hidden;
    position: relative;
    display: flex;

    & button {
      max-width: 300px;
      overflow: hidden;
      background-color: transparent;
      border: none;
      font-family: Arial, Helvetica, sans-serif;      font-size: 16px;
      color: @white;
      cursor: pointer;
    }
  }

  &__lang {
    display: flex;
    align-self: center;
    vertical-align: top;
    height: 100%;
    width: 100%;
    align-items: center;

    &-bl {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;

      button.is-active {
        display: none;
      }
    }
  }

  &__search {
    display: flex;
    align-items: center;
    margin-right: 45px;

    & a {
      display: block;
      width: 20px;
      height: 20px;
      margin-top: 1px;
      background: url("~@/assets/images/icons/svg/search-icon.svg") no-repeat;
    }
  }
}

.tp-sidebar-nav  {
  display: flex;

  &__list {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }

  &__item {
    display: inline-block;
    margin: 0;
    white-space: nowrap;
    border-right: 1px solid #fff;

    & a {
      font-size: 16px;
      color: #fff;
      font-weight: 100;
      text-decoration: none;
      padding: 0 10px 0 10px;
      display: inline;
    }

    &:hover a {
      color: #d73f23 !important;
      text-shadow: 1px 0 0 #d73f23;
    }
  }

  &__item {
    padding: 0;
    display: flex;
    align-items: center;
    left: 0;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 8; /*todo: ask about resizing the menu*/

    &:last-child {
      border: none;
    }

    & a {
      -webkit-transition: all .2s ease-out 0s;
      transition: all .2s ease-out 0s;
      font-family: Arial, Helvetica, sans-serif;
    }
  }
}

.payus_btn {
  position: relative;
  color: @white;
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 16px;


  &:hover, &:focus, &:active {
    color: @white !important;
    text-decoration: none !important;
  }

  &:before {
    content: "";
    position: absolute;
    top: -21px;
    height: 15px;
    width: 100%;
    background: @green;

  }
}

#header-enter {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  overflow: visible;
  background-color: transparent;
  border: none;
  color: @white;

  &:before {
    content: "";
    position: relative;
    width: 30px;
    height: 30px;
    left: -5px;
    background: url("~@/assets/images/img/avatar.png") no-repeat;
  }

  &:after {
    content: "";
    position: absolute;
    top: -10px;
    left: 0;
    height: 15px;
    width: 100%;
    background: @aqua;
  }
}

.tp-header__profile-name {
  font-family: Arial, Helvetica, sans-serif;}

.right-box {
  display: flex;
  align-items: center;
}

.tp-menu__mobile-list {
  left: 15px;
  font-family: Arial, Helvetica, sans-serif;}

.tp-menu__mobile-list.is-active {
  background: #000;
}

.tp-mobile__menu-btn {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 25px;
  margin: auto;
  //display: block;
  width: 25px;
  cursor: pointer;
  background: url("~@/assets/images/icons/png/hamburger.png") no-repeat 0 50%;
}


.cookie_container {
  position: fixed;
  bottom:0;
  z-index: 99998;
  width: 100%;
}

@media  (max-width: 1440px) {
  .header-wrap {
    margin: 0 15px;
    justify-content: space-between;
  }

  .tp-header {
    display: flex;
    align-items: center;
    height: 65px;
    background:#000;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 0;
      box-shadow: none;
    }

    &__profile-menu {
      right: 0;
      left: inherit !important;
    }

    &__logo-holder{
      max-width: 147px;
    }

    &__lang {
      padding: 10px 17px 10px 0;
      &:hover {
        color: @red;
        cursor: pointer;
        font-size: 16px;
        font-family: Arial, Helvetica, sans-serif;
      }

      & a {
        color: @white;
        font-size: 16px;
        font-family: Arial, Helvetica, sans-serif;
        &:hover {
          color: @red;
        }
      }
      &-bl {
        text-transform: uppercase;
      }
    }

    &__right {
      margin-left: 45px;
    }

    &__profile-name  {
      line-height: 45px;

    }

    &__profile_reg {

      & button {
        max-width: 300px;
        overflow: hidden;
        background-color: transparent;
        border: none;
        font-family: Arial, Helvetica, sans-serif;        font-size: 16px;
        color: @white;
      }
    }

    &__search {
      margin-right: 5px;
    }
  }

  .tp-sidebar-nav  {

    &__item {
      border-right: none;
      width: 100%;

      &:last-child {
        border-bottom: none !important;
      }

      a {
        font-family: Arial, Helvetica, sans-serif;
      }
    }
  }

  .payus_btn {
    display: none;

    &:hover, &:focus, &:active {
      color: @white !important;
      text-decoration: none !important;
    }

    &:before {
      content: "";
      position: absolute;
      top: -21px;
      height: 15px;
      width: 100%;
      background: @green;

    }
  }

  #header-enter {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;    text-transform: uppercase;
    overflow: visible;
    background-color: transparent;
    border: none;
    color: @white;

    &:before {
      display: none;
    }

    &:after {
      content: "";
      position: absolute;
      top: -10px;
      left: 0;
      height: 15px;
      width: 100%;
      background: @aqua;
    }
  }

  .tp-header__profile-name {
    font-family: Arial, Helvetica, sans-serif;  }

  .right-box {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .tp-header {
    width: 100%;
    background-size: 100% 100%;
    position: fixed;
    z-index: 99999;
    top: 0;
  }
}

@media screen and (max-width: 1259px) {
  .header-wrap {
    align-items: normal;
  }
  .tp-header {

    &__logo-link {

      & img {
        width: 112px;
        height: 24px;
      }
    }
  }
  .header-socials,
  .tp-header__profile-name,
  .tp-header__right,
  .tp-search__form-input_head,
  .tp-header__profile-avatar .tp-header__profile-unwatch {
    display: none;
  }

  .tp-sidebar-nav {
    height: auto;
    position: absolute;
    overflow: visible;
    top: 45px;
    left: 0;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.5);

    & .tp-sidebar-nav__list {
      flex-direction: column;
      width: 220px;
      background: #000;
      padding: 0 15px;
    }

    &.isActive {
      display: block;

    }

    & .tp-sidebar-nav__item {
      text-align: left;
      border-bottom: 1px solid rgba(230,231,232,0.3) !important;
      border-right: none;

      &:not(:last-child) {
        border-right: none;
      }

      &:last-child {
        border-bottom: none !important;
      }

      &  a {
        -webkit-transition: all .5s;
        -o-transition: all .5s;
        -moz-transition: all .5s;
        transition: all .5s;
        color: #fff;
        display: block !important;
        font-weight: 100;
        text-decoration: none;
        outline: 0;
        font-size: 18px !important;
        padding: 10px 17px 10px 0 !important;
        border-right: 0 !important;
        width: 100%;

        &.tp-header__lang.is-active {
          display: block !important;
        }
      }
    }
  }
  .tp-header__lang_desk {
    display: none !important;
  }

  .tp-header__profile-name_unreg, .hidden-large {
    display: block !important;
  }

  .header {

    &__profile {
      min-width: inherit;
    }

    &__profile-name.tp-header__profile-name_unreg {
      text-transform: uppercase;
      float: left;
      min-width: inherit;

    }

    &-wrap {
      justify-content: flex-end;
    }

    &-logo {
      margin-right: 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 112px;
      margin: 0;
    }

    &__profile-avatar .tp-header__profile-menu {
      top: 30px;
    }
  }

  .tp-header__lang-bl button {
    top: inherit;
    transform: none;
  }
}

@media (max-width: 550px) {
  .tp-header {

    &__search {
      margin: 0;
    }
  }

  .tp-menu__mobile-list {
    & .tp-header__lang-bl {
      display: block;
      width: 120px;
      background: transparent;
    }

    & .tp-header__lang-bl button {
      padding: 0;
      width: 50%;
      border-radius: 12px;
      padding: 10px 17px 10px 0 !important;

      &.is-active, &.is-active:hover {
        background: #fff;
      }

      & span {
        height: auto;
        width: auto;
      }
    }
  }
}

@media screen and (max-width: 520px) {
  #tp-header__search_mob {
    width: 40px;
    height: 40px;
    display: block;
    float: right;
  }

  .tp-hidden__name {
    display: none;
  }
  .tp-header {

    &__profile-avatar {
      float: right;

      .tp-header__profile-menu {
        left: inherit;
        right: 0;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .tp-header__profile-name button#header-enter {
    min-width: inherit;
  }
}

.closed {
  display: none;
}
.cookie-pop {
  position: relative;
  top: 0;
  display: flex;
  font-size: 13px;
  justify-content: center;
  align-items: center;
  background: #d1d3d4;
  z-index: 999999;
  padding: 20px 10px;
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  font-family: 'Arial';
}
.cookie-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: center;
  position: relative;
  z-index: 99999;
}
.cookie__link.tp-video-next__title {
  font-size: 13px;
  max-width: 100%;
  line-height: 1.5;
  font-family: 'Arial', serif;
}
.cookie-text {
  line-height: 1.5;
}

.cookie-agree {
  display: flex;
  margin: 0 0 0 35px;
  padding: 5px 10px;
  align-items: center;
  justify-content: center;
  background: #f04e23;
  color: #fff!important;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 19.5px;
  cursor: pointer;
  outline: none;
  border: none;
}
@media only screen and (max-width: 768px) {
  .cookie-wrap {
    display: block;
  }
  .cookie-agree {
    display: block;
    width: 115px;
    margin: 10px auto 0;
  }
  .cookie-text {
    text-align: justify;
  }
}

.router-link-active {
  color: #d73f23 !important;
  text-shadow: 1px 0 0 #d73f23;
}

.tp-sidebar-nav__item:not(:last-child) {
  border-right: 1px solid #fff;
}

.cookie_container {
  min-height: 78px;
}

@media (max-width: 1011px) {
  .cookie_container {
    min-height: 97px;
  }
}

@media (max-width: 751px) {
  .cookie_container {
    min-height: 136px;
  }
}

@media (max-width: 594px) {
  .cookie_container {
    min-height: 155px;
  }
}

@media (max-width: 371px) {
  .cookie_container {
    min-height: 193px;
  }
}
</style>
