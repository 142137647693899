<template>
  <div class="home">
    <div class="tp-wrapper">
      <h1 style="display: none" v-if="$store.state.lang !=='ru'">
        Головна
      </h1>
      <h1 style="display: none" v-if="$store.state.lang !=='ua'">
        Главная
      </h1>
      <div class="slider-main">
        <singleSlideCarousel/>
      </div>

<!--      <div class="tp-nav__season-title" v-if="news.items">-->
<!--        <router-link :to="'/' + $store.state.lang + '/' + news.typeSlug">{{ news.galleryName }}</router-link>-->
<!--      </div>-->
<!--      <preview :data="news" v-if="news.items"></preview>-->

      <div class="tp-nav__season-title">
        <router-link :to="'/' + $store.state.lang + '/' + series.typeSlug">{{ series.galleryName }}</router-link>
      </div>
      <preview :data="series"></preview>

      <!--  gorizont1  -->
<!--      <div class="g-tag-wrap">-->
<!--        <div id='div-gpt-ad-1629981848210-0' :bind="hor1"></div>-->
<!--      </div>-->
      <!--  gorizont1 end -->

      <div class="tp-nav__season-title">
        <router-link :to="'/' + $store.state.lang + '/' + allshow.typeSlug">{{ allshow.galleryName }}</router-link>
      </div>
      <preview :data="allshow"></preview>


      <div class="tp-nav__season-title" v-show="films.items">
        <router-link :to="'/' + $store.state.lang + '/' + films.typeSlug">{{ films.galleryName }}</router-link>
      </div>
      <PreviewFilms :data="films" v-show="films.items"></PreviewFilms>

      <!--  SLDS_Teleportal_Footer  -->
<!--      <div class="g-tag-wrap">-->
<!--        <div id='div-gpt-ad-1629990708709-0' :bind="foot1"></div>-->
<!--      </div>-->
      <!--  SLDS_Teleportal_Footer end -->

      <!--        <tv-live/>-->
    </div>
    <foot v-if="this.allshow"></foot>
  </div>
</template>

<script>
// @ is an alias to /src

import SingleSlideCarousel from "../components/singleSlideCarousel";
import Preview from "../components/parts/preview";
import PreviewFilms from "../components/parts/previewFilms";
import Foot from "@/components/footer";
// import 'swiper/css/swiper.css'
// import googletag from "@/components/gtm/googletag";
// import tvLive from "../components/parts/tvLive";


export default {
  name: 'Home',
  components: {
    Foot,
    // googletag,
    Preview,
    PreviewFilms,
    SingleSlideCarousel,
    // tvLive,
  },

  beforeRouteUpdate(to, from, next) {
    this.$store.dispatch('getSingleSlide');
    this.$store.dispatch('serials');
    this.$store.dispatch('show');
    this.$store.dispatch('films');
    next();
  },
  beforeCreate() {
    // window.googletag.cmd.push(() => {
    //   window.googletag.destroySlots();
    // });
  },
  mounted() {
    // this.hor1();
    // this.foot1();
    this.$store.dispatch('films');
    this.$store.dispatch('serials');
    this.$store.dispatch('show');
  },
  created() {

    },
  methods: {
    // hor1() {
    //   window.googletag.cmd.push(function () {
    //     if (window.innerWidth > 970) {
    //       window.googletag.defineSlot('/58302844/SLDS_Teleportal_Gorizont1', [[728, 90], [970, 90]], 'div-gpt-ad-1629981848210-0').addService(window.googletag.pubads());
    //     }
    //     else {
    //       window.googletag.defineSlot('/58302844/SLDS_Teleportal_Gorizont1', [320, 50], 'div-gpt-ad-1629981848210-0').addService(window.googletag.pubads());
    //     }
    //     window.googletag.display('div-gpt-ad-1629981848210-0');
    //   });
    //
    // },
    // foot1() {
    //   window.googletag.cmd.push(function () {
    //     if (window.innerWidth > 970) {
    //       window.googletag.defineSlot('/58302844/SLDS_Teleportal_Footer', [[728, 90], [970, 90]], 'div-gpt-ad-1629990708709-0').addService(window.googletag.pubads());
    //     }
    //     else {
    //       window.googletag.defineSlot('/58302844/SLDS_Teleportal_Footer', [320, 50], 'div-gpt-ad-1629990708709-0').addService(window.googletag.pubads());
    //     }
    //     window.googletag.display('div-gpt-ad-1629990708709-0');
    //   });
    // }
  },
  computed: {
    allshow() {
      return this.$store.state.allshow;
    },
    series() {
      return this.$store.state.series;
    },
    films() {
      return this.$store.state.films;
    },
    // singleSlide() {
    //   return this.$store.state.singleSlide;
    // }
  },
}


</script>
