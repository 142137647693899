import Vue from 'vue';
import Vuex from 'vuex';
// import router from "@/router";
// import App from "@/App";
import axios from "axios";
import config from '../../public/js/config.js'

Vue.use(Vuex);

// let lang = 'ua'
let axiosInstanse = axios.create({
    // baseURL: "https://tp-back-stage.starlight.digital",
    baseURL: config.VUE_APP_API_BASE_URL || process.env.VUE_APP_API_BASE_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
})


const apiData =
    {
        series: 'serials',
        films: 'documentaries',
        search: 'search',
        singleSlide: 'top-projects',
        allshow: 'show',
        headerText: 'header-data',
        footerText: 'footer-data',
        mainPageText: 'main_page',
        // project: "projectPage",
        persons: "persons",
        personsSeasons: "personsSeasons",
        person: "person",
        show_serials_text: "show_serials_text",
        contacts: "contacts"
    }
;

export const store = new Vuex.Store({

    state: {
        lang: 'ua',
        imgDomain: config.VUE_APP_API_BASE_IMG || '',
        series: [],
        films: [],
        search: [],
        singleSlide: {},
        allshow: [],
        loadSpinner: true,
        headerText: [],
        footerText: [],
        mainPageText: [],
        // project: {},
        persons: [],
        person: [],
        personsSeasons: [],
        show_serials_text: [],
        contacts: {}
    },

    actions: {
        home() {
            // store.dispatch('show')
            // store.dispatch('serials')
        },
        serials({commit}) {
            let vm = this
            Vue.axios.get(axiosInstanse.defaults.baseURL + '/' + store.state.lang + '/' + apiData.series)
                .then(result => {
                    commit('SAVE_SERIES', result.data);
                    vm.loading = false
                    // this.info = data
                    // console.log(result.data)
                })
                .catch(err => {
                    if (err) {
                        console.log(err)
                        // window.location.href = '/' + this.$store.state.lang + '/404'
                    }
                })
        },
        films({commit}) {
            let vm = this
            Vue.axios.get(axiosInstanse.defaults.baseURL + '/' + store.state.lang + '/' + apiData.films)
                .then(result => {
                    commit('SAVE_FILMS', result.data);
                    vm.loading = false
                    // this.info = data
                    // console.log(result.data)
                })
                .catch(err => {
                    if (err) {
                        console.log(err)
                        // window.location.href = '/' + this.$store.state.lang + '/404'
                    }
                })
        },
        getSingleSlide({commit}) {
            let vm = this
            Vue.axios.get(axiosInstanse.defaults.baseURL + '/' + store.state.lang + '/' + apiData.singleSlide)
                .then(result => {
                    commit('SAVE_SINGLE', result.data);
                    vm.loading = false
                    // this.info = data
                    // console.log(result.data)
                })
                .catch(err => {
                    if (err) {
                        console.log(err)
                        window.location.href = '/' + this.$store.state.lang + '/404'
                    }
                })
        },
        show({commit}) {
            let vm = this
            Vue.axios.get(
                axiosInstanse.defaults.baseURL + '/' + store.state.lang + '/' + apiData.allshow
            )
                .then(result => {
                    commit('SAVE_PREVIEW', result.data);
                    vm.loading = false
                    // this.info = data
                    // console.log(result.data)
                    // this.$forceUpdate();
                })
                .catch(err => {
                    if (err) {
                        console.log(err)
                        // window.location.href = '/' + this.$store.state.lang + '/404'
                    }
                })
        },
        getHeaderText({commit}) {
            Vue.axios.get(axiosInstanse.defaults.baseURL + '/' + store.state.lang + '/' + apiData.headerText)
                .then(result => {
                    commit('SAVE_HEADERTEXT', result.data);
                    // this.info = data
                    // console.log(result.data)
                })
                .catch(err => {
                    if (err) {
                        console.log(err)
                        window.location.href = '/' + this.$store.state.lang + '/404'
                    }
                })
        },
        getFooterText({commit}) {
            Vue.axios.get(axiosInstanse.defaults.baseURL + '/' + store.state.lang + '/' + apiData.footerText)
                .then(({data}) => {
                    commit('SAVE_FOOTERTEXT', data);
                })
                .catch(err => {
                    if (err) {
                        console.log(err)
                        window.location.href = '/' + this.$store.state.lang + '/404'
                    }
                })
        },
        getMainPageText({commit}) {
            Vue.axios.get(axiosInstanse.defaults.baseURL + '/' + store.state.lang + '/' + apiData.mainPageText)
                .then(result => {
                    commit('SAVE_MAINPAGETEXT', result.data);
                    // this.info = data
                    // console.log(result.data)
                })
                .catch(err => {
                    if (err) {
                        console.log(err)
                        window.location.href = '/' + this.$store.state.lang + '/404'
                    }
                })
        },
        persons({commit}) {
            Vue.axios.get(axiosInstanse.defaults.baseURL + '/' + store.state.lang + '/' + apiData.personsSeasons)
                .then(result => {
                    commit('SAVE_PERSONS', result.data);
                    // this.info = data
                    // console.log(result.data)
                })
                .catch(err => {
                    if (err) {
                        console.log(err)
                        window.location.href = '/' + this.$store.state.lang + '/404'
                    }
                })
        },
        personsSeasons({commit}) {
            Vue.axios.get(axiosInstanse.defaults.baseURL + '/' + store.state.lang + '/' + apiData.persons)
                .then(result => {
                    commit('SAVE_PERSONS', result.data);
                    // this.info = data
                    // console.log(result.data)
                })
                .catch(err => {
                    if (err) {
                        console.log(err)
                        window.location.href = '/' + this.$store.state.lang + '/404'
                    }
                })
        },
        person({commit}) {
            Vue.axios.get(axiosInstanse.defaults.baseURL + '/' + store.state.lang + '/' + apiData.person)
                .then(result => {
                    commit('SAVE_PERSON', result.data);
                    // this.info = data
                    console.log(result.data)
                })
                .catch(err => {
                    if (err) {
                        console.log(err)
                        window.location.href = '/' + this.$store.state.lang + '/404'
                    }
                })
        },
        show_serials_text({commit}) {
            Vue.axios.get(axiosInstanse.defaults.baseURL + '/' + store.state.lang + '/' + apiData.show_serials_text)
                .then(result => {
                    commit('SAVE_SHOW_SERIALS_TEXT', result.data);
                    // this.info = data
                    console.log(result.data)
                })
                .catch(err => {
                    if (err) {
                        console.log(err)
                        // window.location.href = '/' + this.$store.state.lang + '/404'
                    }
                })
        },
        contacts({commit}) {
            Vue.axios.get(axiosInstanse.defaults.baseURL + '/' + store.state.lang + '/' + apiData.contacts)
                .then(result => {
                    commit('SAVE_CONTACTS', result.data);
                    // this.info = data
                    console.log(result.data)
                })
                .catch(err => {
                    if (err) {
                        console.log(err)
                        window.location.href = '/' + this.$store.state.lang + '/404'
                    }
                })
        },
        changeLang({commit}) {
            let newLang = 'ua'
            if (store.state.lang === 'ua') {
                newLang = 'ru'
            }
            commit('CHANGE_LANG', newLang);
        },
    },

    mutations: {
        SAVE_SERIES(state, series) {
            state.series = series;
        },
        SAVE_FILMS(state, films) {
            state.films = films;
        },
        SAVE_SINGLE(state, singleSlide) {
            state.singleSlide = singleSlide;
        },
        SAVE_PREVIEW(state, allshow) {
            state.allshow = allshow;
        },
        SAVE_HEADERTEXT(state, headerText) {
            state.headerText = headerText;
        },
        SAVE_FOOTERTEXT(state, footerText) {
            state.footerText = footerText;
        },
        SAVE_MAINPAGETEXT(state, mainPageText) {
            state.mainPageText = mainPageText;
        },
        // SAVE_PROJECT(state, project) {
        //     state.project = project;
        // },
        SAVE_PERSONS(state, persons) {
            state.persons = persons;
        },
        SAVE_PERSONS_SEASONS(state, personsSeasons) {
            state.personsSeasons = personsSeasons;
        },
        SAVE_PERSON(state, person) {
            state.person = person;
        },
        SAVE_SHOW_SERIALS_TEXT(state, show_serials_text) {
            state.show_serials_text = show_serials_text;
        },
        SAVE_CONTACTS(state, contacts) {
            state.contacts = contacts;
        },
        CHANGE_LANG(state, newLang) {
            state.lang = newLang;
        },
    },
    getters: {
        getLanguage: state => state.lang
    }
});

