<template>
  <swiper class="tp-channel__slider-item" ref="mySwiper" :auto-update="false" :options="swiperOptions">
    <swiper-slide v-for='(element, index) in singleSlide.items' :key="index">
      <router-link
          :to="'/' + $store.state.lang + '/'+ element.typeSlug + '/' + element.channelSlug + '/' + element.projectSlug ">
        <img v-if="bigImgVisible" v-lazy="$store.state.imgDomain + element.image" :alt="element.name">
        <img v-if="tabImgVisible" v-lazy="$store.state.imgDomain + element.imageTab" :alt="element.name">
        <img v-if="mobImgVisible" v-lazy="$store.state.imgDomain + element.imageMob" :alt="element.name">

      </router-link>
    </swiper-slide>
    <div class="swiper-pagination swiper-pagination-bullets" slot="pagination"></div>
  </swiper>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
// import axios from "axios";

export default {
  name: "singleSlide",
  title: 'Custom pagination',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      bigImgVisible: false,
      mobImgVisible: false,
      tabImgVisible: false,
      swiperOptions: {

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        loop: true,
        allowTouchMove: true,
        allowSlideNext: true,
        allowSlidePrev: true,
        slideToClickedSlide: true,
        autoplayDisableOnInteraction: false,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        effect: 'fade',
        speed: 1200,
        items: 1,
        // breakpoints: {
        //   768: {
        //     slidesPerGroup: 1,
        //     slidesPerView: 'auto',
        //     speed: 500,
        //     freeMode: true,
        //     grabCursor: false,
        //     allowTouchMove: true,
        //     allowSlideNext: false,
        //     allowSlidePrev: false,
        //
        //   }
        // }
      }
    }
  },
  metaInfo() {
    const mainMeta = this.singleSlide
    if (mainMeta) {
      return {
        title: mainMeta.seo_title,
        meta: [
          {
            name: 'description',
            content: mainMeta.seo_description
          },
          {name: 'keywords', content: mainMeta.seo_keywords}
        ]
      }
    }

  },

  state: {
    // singleSlide: []
  },
  mounted() {

    // axios.get('https://tp-api.starlight.digital/shows/by-channel/2')
    //     .then(({data}) => {
    //       this.info = data
    //     })
    console.log('Current Swiper instance object', this.swiper)
  },

  created() {
    this.getWinSize();
    window.addEventListener('resize', this.getWinSize);
    this.$store.dispatch('getSingleSlide');
  },
  updated() {
    // this.$store.dispatch('getSingleSlide');
    let vm = this
    vm.loading = false
    this.$refs.mySwiper.swiperInstance.update();
  },

  destroyed() {
    // window.removeEventListener('resize', this.getWinSize);
  },
  methods: {
    getWinSize() {
        if (window.visualViewport.width > 960) {
          this.bigImgVisible = true
          this.tabImgVisible = false
          this.mobImgVisible  = false
        }
        else if (window.visualViewport.width <= 960 && window.visualViewport.width > 480 ) {
          this.bigImgVisible = false
          this.tabImgVisible = true
          this.mobImgVisible  = false

        }
        else if(window.visualViewport.width < 480 ) {
          this.bigImgVisible = false
          this.tabImgVisible = false
          this.mobImgVisible  = true
        }
        // this.$refs.mySwiper.swiperInstance.update()-->
        },
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
    singleSlide() {
      return this.$store.state.singleSlide;
    }
  },
}
</script>

<style scoped>
.swiper-pagination-bullet-custom {
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
  text-align: center;
  color: red;
  opacity: 0.7;
  background: rgba(0.0 .0 .1, 0.2);
  transition: all .2s;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  color: #fff;
  background: #007aff;
}
</style>


<!--<script>-->
<!--import {Swiper, SwiperSlide} from 'vue-awesome-swiper'-->
<!--import 'swiper/css/swiper.css'-->
<!--// import axios from "axios";-->

<!--export default {-->
<!--  name: "singleSlide",-->
<!--  title: 'Custom pagination',-->
<!--  components: {-->
<!--    Swiper,-->
<!--    SwiperSlide-->
<!--  },-->
<!--  data() {-->
<!--    return {-->
<!--      bigImgVisible: false,-->
<!--      mobImgVisible: false,-->
<!--      swiperOptions: {-->

<!--        pagination: {-->
<!--          el: '.swiper-pagination',-->
<!--          clickable: true,-->
<!--        },-->
<!--        loop: true,-->
<!--        allowTouchMove: true,-->
<!--        allowSlideNext: true,-->
<!--        allowSlidePrev: true,-->
<!--        slideToClickedSlide: true,-->
<!--        autoplayDisableOnInteraction: false,-->
<!--        autoplay: {-->
<!--          delay: 3000,-->
<!--          disableOnInteraction: false-->
<!--        },-->
<!--        effect: 'fade',-->
<!--        speed: 1200,-->
<!--        items: 1,-->
<!--        // breakpoints: {-->
<!--        //   768: {-->
<!--        //     slidesPerGroup: 1,-->
<!--        //     slidesPerView: 'auto',-->
<!--        //     speed: 500,-->
<!--        //     freeMode: true,-->
<!--        //     grabCursor: false,-->
<!--        //     allowTouchMove: true,-->
<!--        //     allowSlideNext: false,-->
<!--        //     allowSlidePrev: false,-->
<!--        //-->
<!--        //   }-->
<!--        // }-->
<!--      }-->
<!--    }-->
<!--  },-->
<!--  metaInfo() {-->
<!--    const mainMeta = this.singleSlide-->
<!--    if(mainMeta) {-->
<!--      return {-->
<!--        title: mainMeta.seo_title,-->
<!--        meta: [-->
<!--          {-->
<!--            name: 'description',-->
<!--            content: mainMeta.seo_description-->
<!--          },-->
<!--          {name: 'keywords', content: mainMeta.seo_keywords}-->
<!--        ]-->
<!--      }-->
<!--    }-->

<!--  },-->

<!--  state: {-->
<!--    // singleSlide: []-->
<!--  },-->

<!--  mounted() {-->
<!--    // axios.get('https://tp-api.starlight.digital/shows/by-channel/2')-->
<!--    //     .then(({data}) => {-->
<!--    //       this.info = data-->
<!--    //     })-->
<!--    console.log('Current Swiper instance object', this.swiper)-->
<!--  },-->

<!--  methods: {-->
<!--    getWinSize() {-->
<!--      if (window.innerWidth > 768) {-->
<!--        this.bigImgVisible = true-->
<!--      } else if (window.innerWidth < 768 && window.innerWidth > 510 ) {-->
<!--        this.bigImgVisible = false-->
<!--      }-->
<!--      else if(window.innerWidth < 509 ) {-->
<!--        this.mobImgVisible  = true-->
<!--      }-->
<!--      // this.$refs.mySwiper.swiperInstance.update()-->
<!--    },-->
<!--  },-->
<!--  created() {-->
<!--    this.getWinSize();-->
<!--    window.addEventListener('resize', this.getWinSize);-->
<!--    this.$store.dispatch('getSingleSlide');-->
<!--  },-->
<!--  updated() {-->
<!--    // this.$store.dispatch('getSingleSlide');-->
<!--    let vm = this-->
<!--    vm.loading = false-->
<!--    this.$refs.mySwiper.swiperInstance.update();-->
<!--  },-->
<!--  destroyed() {-->
<!--    window.removeEventListener('resize', this.getWinSize);-->
<!--  },-->
<!--  computed: {-->
<!--    swiper() {-->
<!--      return this.$refs.mySwiper.$swiper-->
<!--    },-->
<!--    singleSlide() {-->
<!--      return this.$store.state.singleSlide;-->
<!--    }-->
<!--  },-->
<!--}-->
<!--</script>-->

<!--<style scoped>-->
<!--.swiper-pagination-bullet-custom {-->
<!--  width: 20px !important;-->
<!--  height: 20px !important;-->
<!--  line-height: 20px !important;-->
<!--  text-align: center;-->
<!--  color: red;-->
<!--  opacity: 0.7;-->
<!--  background: rgba(0.0 .0 .1, 0.2);-->
<!--  transition: all .2s;-->
<!--}-->

<!--.swiper-pagination-bullet-active {-->
<!--  opacity: 1;-->
<!--  color: #fff;-->
<!--  background: #007aff;-->
<!--}-->
<!--</style>-->