<template>
  <div class="tp-wrapper">
    <div class="row-content">

        <div class="tp-404-wrapper">
          <div class="tp-404_holder">
            <div class="text-404">
              <h2 v-if="$store.state.lang !== 'ua'">
                Ошибка... К сожалению,<br>
                что-то пошло не так:<br>
                Скорее всего, мы уже<br>
                в курсе и пытаемся быстро<br>
                все починить. Посмотрите<br>
                пока что-нибудь другое -<br>
                у нас тут много всего :)
              </h2>
              <h2 v-if="$store.state.lang !== 'ru'">
                Помилка... На жаль,<br>
                щось пішло не так:(<br>
                Швидше за все, ми вже<br>
                в курсі і намагаємося швидко<br>
                все полагодити. Подивіться<br>
                поки що-небудь інше -<br>
                у нас тут багато всього:)
              </h2>
            </div>
            <div class="image-404">
              <div class="tp-404__holder"><img src="../../src/assets/images/icons/svg/404.svg"></div>
            </div>
          </div>
        </div>

    </div>
    <swiper class="tp-404" ref="mySwiper" :options="swiperOptions">
      <swiper-slide class="tp-show__list-item tp-show__list-item_nodescr" v-for='(item, index) in allshow.items'
                    v-bind:key="index">
        <div class="hover-block">
          <router-link :to="allshow.typeSlug + '/' + item.channelSlug + '/' + item.projectSlug">
            <div class="tp-show__list-item__thumb">
              <img v-bind:src="item.image" style="opacity: 1;" :alt="item.name">

            </div>
          </router-link>
        </div>
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
  <foot v-if="this.allshow"></foot>
  </div>
</template>

<script>
// import axios from "axios";
// import config from "../../../public/js/config";
// import Preview from "../components/parts/preview";
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import Foot from "@/components/footer";
// import 'swiper/css/swiper.css'
export default {
  name: "notFound",
  components: {
    Foot,
    // Breadcrumps,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        loop: false,
        allowTouchMove: true,
        allowSlideNext: true,
        allowSlidePrev: true,
        initialSlide: 0,
        speed: 500,
        slidesPerView: 'auto',
        spaceBetween: 0,
        watchOverflow: true,
        autoplay: false,
        // preloadImages: true,
        // watchSlidesProgress: true,
        breakpoints: {
          768: {
            slidesPerGroup: 3,
            spaceBetween: 0,
            allowTouchMove: false,
            slidesPerView: 'auto',
            speed: 500,
            // freeMode: true,
            // grabCursor: true,
          }
        }
      }
    }
  },
  metaInfo() {

    return {
      title: 'Page not found',
      meta: [
        {
          name: 'description',
          // content: this.personData.seoDescription
        },
        {
          name: 'keywords',
          // content: this.personData.seoKeywords
        }
      ]
    }
  },
  beforeCreate() {
    this.$store.dispatch('getHeaderText')
    this.$store.dispatch('getFooterText')
    this.$store.dispatch('show');
  },
  beforeMount() {
    console.log('this.$route', this.$route)

    // window.location.href = '/' + this.$store.state.lang + '/404'
    // if (this.$route.name === 'notFound') {
    //   axios.get(config.VUE_APP_API_BASE_URL + '/404')
    //       .then(response => (this.projectData = response.data))
    //       .catch(err => {
    //         if (err) {
    //           console.log(err)
              // this.$router.push('notFound')
              // this.$router.push('/' + this.$store.state.lang + '/404')
              // window.location.href = '/' + this.$store.state.lang + '/404'
            // }
          // })
    // }
  },
  updated() {
    this.$refs.mySwiper.swiperInstance.update();
  },
  computed: {
    allshow() {
      return this.$store.state.allshow;
    },
  },
}
</script>

<style scoped lang="less">
.tp-404 {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: block;
  padding-bottom: 70px;
}

.tp-404 .tp-show__list {
  margin-left: 0;
}

.tp-404 .image-404 {
  order: 1 !important;
}

.tp-404 .tp-section__channel {
  margin: 0 auto 0;
}

.tp-404-wrapper {
  display: block;
  vertical-align: middle;
  padding: 100px 0;
}

.tp-404_holder {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto;
  //max-width: 750px;
  width: 100%;
}

.tp-404_holder .tp-404-desc {
  margin: 50px auto 30px auto;
  text-align: center;
}

.tp-404_holder .tp-404__holder {
  display: block;
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
}

.tp-404_holder .tp-404__holder img {
  width: 100%;
}

.tp-404_holder h2 {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 100;
  color: #fff;
  text-align: left;
}
.tp-404__logo-holder {
  width: 100%;
  max-width: 210px;
  border: 1px solid #fff;
  margin: 0 auto;
  padding: 10px 30px 10px 30px;
}

.tp-404__logo-holder img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .tp-404-wrapper {
    padding: 0;
  }

  .tp-404_holder h2,
  .tp-404_holder h2 span {
    font-size: 5vw;
    text-align: center;
  }

  .tp-404_holder {
    flex-direction: column;
  }

  .tp-404 .image-404 {
    margin-top: 50px;
  }
}

@media screen and (max-width: 420px) {
  .tp-404_holder h2,
  .tp-404_holder h2 span {
    text-align: center;
  }
}

.tp-404 .tp-show__list-item {
  width: 240px !important;
}

.swiper-button-next, .swiper-button-prev {
  color: #fff !important;
  opacity: 0.7 !important;
  top: 33%;
  &:hover {
    opacity: 1;
  }
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  pointer-events: all !important;
  display: none;
}
</style>
