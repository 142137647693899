<template>
  <div class="tp-footer">
    <!--    <div class="pop-pay">-->
    <!--      <iframe width="auto" class="pay-frame" height="auto" id="pay-popup" src=""-->
    <!--              sandbox="allow-scripts allow-top-navigation allow-forms allow-same-origin"></iframe>-->
    <!--    </div>-->
    <div class="tp-footer-wrap">
      <div class="tp-footer__menu-wrap">
        <ul class="tp-footer__menu">
          <li v-for="(item, index) in footerText" :key="index">
            <router-link :to="'/' + $store.state.lang + '/' + item.slug">{{ item.title }}</router-link>
          </li>

        </ul>
      </div>
    </div>
    <div class="footer-links">
<!--      <img src="@/assets/images/img/visa.png" class="footer-links__img" alt="">-->
<!--      <img src="@/assets/images/img/mastercard.png" class="footer-links__img" alt="">-->
      <!--        <a href="https://play.google.com/store/apps/details?id=com.teleportal.video"-->
      <!--           rel="noreferrer"-->
      <!--           target="_blank">-->
      <!--          <img src="@/assets/images/img/google_app.png" class="footer-links__img" alt="">-->
      <!--        </a>-->
      <!--        <a href="https://apps.apple.com/ua/app/teleportal/id1485890645?l=0uk"-->
      <!--           rel="noreferrer"-->
      <!--           target="_blank">-->
      <!--          <img src="@/assets/images/img/apple_app.png" class="footer-links__img" alt="">-->
      <!--        </a>-->

    </div>

    <p class="tp-footer__copy">Teleportal © 2018-<span v-html="new Date().getFullYear() + '&nbsp'"></span>СЛМ ОНЛАЙН МЕДІА</p>
  </div>
</template>

<script>
export default {
  name: "foot",
  data() {
    return {
      vm: this,
    }
  },
  state: {
    footerText: []
  },
  watch: {
    $route: function () {
      // this.$store.dispatch('getFooterText')
    },
  },
  mounted() {
  },
  computed: {
    footerText() {
      return this.$store.state.footerText
    },
  }
}

</script>

<style scoped lang="less">

</style>