<template>
    <div class="single-slide-wrap">

            <single-slide/>

    </div>
</template>

<script>

    import Vue from 'vue'


    export default {
        name: "singleSlideCarousel",
        components: {SingleSlide },

    }

    import SingleSlide from "./parts/singleSlide";

    Vue.component('slide', {
        props: ['title'],

    })

</script>

<style scoped>
.slider-main .single-slide-wrap {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 38.22% 0 0 0;
}

.slider-main .tp-channel__slider-item {
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;

}
</style>