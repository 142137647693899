<template>
  <div>
    <swiper class="tp-show__list foo"
            ref="mySwiper" :options="swiperOptions" :auto-update="false">
      <swiper-slide class="tp-show__list-item hover-block tp-show__list-item_nodescr" v-for='(item, index) in data.items'
                    v-bind:key="index">
          <router-link
              :to="'/' + $store.state.lang +'/' + data.typeSlug + '/' + item.channelSlug + '/' + item.projectSlug">
            <div class="tp-show__list-item__thumb">
              <img v-if="deskImg" v-lazy="$store.state.imgDomain + item.image + '?v=' + img_version" :alt="item.name">
              <img v-if="mobImg" v-lazy="$store.state.imgDomain + item.imageMob + '?v=' + img_version" :alt="item.name">
            </div>
          </router-link>
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>

  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'


export default {
  name: "previewComponent",
  title: 'Custom pagination',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      img_version: new Date(document.lastModified).getTime(),
      vm: this,
      deskImg: true,
      mobImg: false,
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        loop: false,
        allowTouchMove: true,
        allowSlideNext: true,
        allowSlidePrev: true,
        initialSlide: 0,
        speed: 500,
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup: 1,
        watchOverflow: true,
        autoplay: false,
        // preloadImages: true,
        // watchSlidesProgress: true,
        breakpoints: {
          768: {
            slidesPerGroup: 3,
            spaceBetween: 0,
            allowTouchMove: false,
            slidesPerView: 'auto',
            speed: 500,
            // freeMode: true,
            grabCursor: true,
          }
        }
      }
    }
  },
  props: ['data'],
  state: {
    allshow: [],
  },
  computed: {
    // series() {
    //   return this.$store.state.series;
    // },
    // allshow() {
    //   return this.$store.state.allshow;
    // },
    swiper() {
      return this.$refs.mySwiper.$swiper
    }

  },
  beforeCreate() {
    // this.$store.dispatch('serials');
    // this.$store.dispatch('show');
  },
  created() {
    this.getWinSize();
  },
  mounted() {
    console.log(this.$store.state.allshow)

  },
  updated() {
    this.$refs.mySwiper.swiperInstance.update();
  },
  methods: {
    getWinSize() {
      if (window.visualViewport.width > 510) {
        this.deskImg = true
        this.mobImg = false
      } else if (window.visualViewport.width <= 510) {
        this.deskImg = false
        this.mobImg = true
      }
    },
  }
}


</script>

<style lang="less" scoped>
.swiper-button {

  &-next, &-prev {
    color: #fff !important;
    opacity: .7 !important;

    &:hover {
      opacity: 1 !important;
    }
  }
}

@media (max-width: 510px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  pointer-events: all !important;
  display: none;
}

.swiper-container {
  min-height: 150px;
}


@media (max-width: 768px) {
  .swiper-container {
    min-height: 99px;
  }
}
</style>